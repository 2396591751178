import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TagManager from 'react-gtm-module';
import Item from './item';
import ExportXLS from '../../components/ExportXLS';

// Layout
import layout from '../../config/product-layout';
import { getNonEmptyFields, getProductImpressions } from '../../scripts/utils';

const display = layout.product;

const styles = () => ({
  headCell: {
    whiteSpace: 'nowrap',
  },

  valueCell: {},
});

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(165, 216, 218, 0.15)',
    },
  },
}))(TableRow);

function ProductSummary(props) {
  const { classes, products } = props;
  const nonEmptyFields = getNonEmptyFields(display.fields, products);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'productsCompare',
        ecommerce: {
          detail: {
            actionField: { list: 'Products Compare' }, // 'detail' actions have an optional list property.
            products: getProductImpressions(products, 'Product Details'),
          },
        },
      },
    });
    return () => {};
  }, [products]);

  return (
    <>

      <TableRow>
        <TableCell
          colSpan={products.length + 1}
          className={classes.headerCell}
        >
          <h3>Overview</h3>
          <ExportXLS
            data={products}
            fields={display.fields}
            button="Export"
          />
        </TableCell>
      </TableRow>
      {Object.keys(nonEmptyFields).map(name => {
        if (nonEmptyFields[name].type === 'subform') {
          return null;
        }
        if (nonEmptyFields[name].type === 'files') {
          return null;
        }
        return (
          <StyledTableRow key={nonEmptyFields[name].label}>
            <TableCell
              className={classes.headCell}
              key={nonEmptyFields[name].label}
            >
              {nonEmptyFields[name].label}
            </TableCell>
            {products.map(product => (
              <TableCell
                className={classes.valueCell}
                key={`${product.id}-${nonEmptyFields[name].label}`}
              >
                <Item
                  value={product[name]}
                  options={display.fields[name].options || {}}
                />
              </TableCell>
            ))}
          </StyledTableRow>
        );
      })}
    </>
  );
}

ProductSummary.defaultProps = {};

ProductSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
};

export default withStyles(styles)(ProductSummary);
