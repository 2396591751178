import {
  FILTER_INGREDIENTS,
  SELECT_CATEGORY,
  CREATE_CATEGORY,
} from '../queries';

const json = {
  product: {
    name: 'product',
    filters: [
      'category',
      'subcategory',
      'subformat',
      'format',
      'brand_owner',
      'brand_name',
      'variant_name',
      'country',
      'region',
      'manufacturer',
      'manufacturer_location',
      'market',
      'status',
      'date_ingredient_list',
      'ingredient'
    ],

    fields: {
      ingredient: {
        label: 'Ingredient',
        type: 'filter',
        options: {
          renderAs: 'graphfilter',
          query: FILTER_INGREDIENTS,
        },
      },
      file: {
        label: 'File',
        default: '',
        type: 'files',
        width: {
          sm: 12,
        },
      },
      market: {
        default: '',
        type: 'select',
        options: ['HOME CARE', 'I&I', 'PERSONAL CARE'],
        label: 'Market',
      },
      category: {
        label: 'Category',
        default: '',
        type: 'select',
        xtype: 'creatable',
        xoptions: {
          query: SELECT_CATEGORY,
          mutate: CREATE_CATEGORY,
        },

        options: [
          'Laundry',
          'Dish',
          'Hard Surface',
          'Bleach',
          'Air',
          'Polish',
          'Insecticides',
          'Skin care',
          'Personal care',
          'Fragrances',
          'Cosmetics',
        ],
      },
      subcategory: {
        label: 'Subcategory',
        default: '',
        type: 'select',
        options: [
          'Fabric washing',
          'Fabric conditioning',
          'Additives',
          'Automatic dish washing',
          'Hand dish washing',
          'Bathroom cleaning',
          'Kitchen cleaning',
          'All purpose cleaning',
          'Floor cleaning',
          'Glass cleaning',
          'Sun',
          'Baby & Child',
          'Hair',
          'Shower & Bath',
          'Oral care',
          'Deodorants',
          'Shaving',
          'Body',
          'Face',
          'Lips',
          'Eyes',
          'Hand',
          'Nails',
          'Toilet',
          'Drain deblocker',
        ],
      },
      format: {
        label: 'Format',
        default: '',
        type: 'select',
        options: [
          'Liquid',
          'Powder',
          'Unit dose',
          'Wipes',
          'Creams',
          'Pastes',
          'Aerosols',
          'Lotion',
        ],
      },
      subformat: {
        label: 'Subformat',
        default: '',
        type: 'select',
        options: [
          'Capsules',
          'Concentrated',
          'Gel',
          'Standard',
          'Spray',
          'Tablet',
          'Multi-compartment',
          'Diluted',
        ],
      },
      brand_name: {
        label: 'Brand name',
        default: '',
        type: 'string',
      },
      variant_name: {
        label: 'Variant name',
        default: '',
        type: 'string',
      },
      brand_owner: {
        label: 'Brand owner',
        default: '',
        type: 'string',
      },
      country: {
        label: 'Country',
        default: '',
        type: 'filteredSelect',
        options: [
          'Afghanistan',
          'Albania',
          'Algeria',
          'Andorra',
          'Angola',
          'Antigua and Barbuda',
          'Argentina',
          'Armenia',
          'Australia',
          'Austria',
          'Azerbaijan',
          'Bahamas',
          'Bahrain',
          'Bangladesh',
          'Barbados',
          'Belarus',
          'Belgium',
          'Belize',
          'Benin',
          'Bhutan',
          'Bolivia',
          'Bosnia and Herzegovina',
          'Botswana',
          'Brazil',
          'Brunei',
          'Bulgaria',
          'Burkina Faso',
          'Burundi',
          'Cabo Verde',
          'Cambodia',
          'Cameroon',
          'Canada',
          'Central African Republic',
          'Chad',
          'Chile',
          'China',
          'Colombia',
          'Comoros',
          'Congo, Democratic Republic of the',
          'Congo, Republic of the',
          'Costa Rica',
          'Côte d’Ivoire',
          'Croatia',
          'Cuba',
          'Cyprus',
          'Czech Republic',
          'Denmark',
          'Djibouti',
          'Dominica',
          'Dominican Republic',
          'East Timor (Timor-Leste)',
          'Ecuador',
          'Egypt',
          'El Salvador',
          'Equatorial Guinea',
          'Eritrea',
          'Estonia',
          'Eswatini',
          'Ethiopia',
          'Fiji',
          'Finland',
          'France',
          'Gabon',
          'The Gambia',
          'Georgia',
          'Germany',
          'Ghana',
          'Greece',
          'Grenada',
          'Guatemala',
          'Guinea',
          'Guinea-Bissau',
          'Guyana',
          'Haiti',
          'Honduras',
          'Hungary',
          'Iceland',
          'India',
          'Indonesia',
          'Iran',
          'Iraq',
          'Ireland',
          'Israel',
          'Italy',
          'Jamaica',
          'Japan',
          'Jordan',
          'Kazakhstan',
          'Kenya',
          'Kiribati',
          'Korea, North',
          'Korea, South',
          'Kosovo',
          'Kuwait',
          'Kyrgyzstan',
          'Laos',
          'Latvia',
          'Lebanon',
          'Lesotho',
          'Liberia',
          'Libya',
          'Liechtenstein',
          'Lithuania',
          'Luxembourg',
          'Madagascar',
          'Malawi',
          'Malaysia',
          'Maldives',
          'Mali',
          'Malta',
          'Marshall Islands',
          'Mauritania',
          'Mauritius',
          'Mexico',
          'Micronesia, Federated States of',
          'Moldova',
          'Monaco',
          'Mongolia',
          'Montenegro',
          'Morocco',
          'Mozambique',
          'Myanmar (Burma)',
          'Namibia',
          'Nauru',
          'Nepal',
          'Netherlands',
          'New Zealand',
          'Nicaragua',
          'Niger',
          'Nigeria',
          'North Macedonia',
          'Norway',
          'Oman',
          'Pakistan',
          'Palau',
          'Panama',
          'Papua New Guinea',
          'Paraguay',
          'Peru',
          'Philippines',
          'Poland',
          'Portugal',
          'Qatar',
          'Romania',
          'Russia',
          'Rwanda',
          'Saint Kitts and Nevis',
          'Saint Lucia',
          'Saint Vincent and the Grenadines',
          'Samoa',
          'San Marino',
          'Sao Tome and Principe',
          'Saudi Arabia',
          'Senegal',
          'Serbia',
          'Seychelles',
          'Sierra Leone',
          'Singapore',
          'Slovakia',
          'Slovenia',
          'Solomon Islands',
          'Somalia',
          'South Africa',
          'Spain',
          'Sri Lanka',
          'Sudan',
          'Sudan, South',
          'Suriname',
          'Sweden',
          'Switzerland',
          'Syria',
          'Taiwan',
          'Tajikistan',
          'Tanzania',
          'Thailand',
          'Togo',
          'Tonga',
          'Trinidad and Tobago',
          'Tunisia',
          'Turkey',
          'Turkmenistan',
          'Tuvalu',
          'Uganda',
          'Ukraine',
          'United Arab Emirates',
          'United Kingdom',
          'United States',
          'Uruguay',
          'Uzbekistan',
          'Vanuatu',
          'Vatican City',
          'Venezuela',
          'Vietnam',
          'Yemen',
          'Zambia',
          'Zimbabwe',
        ],
      },
      region: {
        label: 'Region geography',
        default: '',
        type: 'select',
        options: [
          'Africa',
          'Oceania',
          'Europe',
          'Latin America',
          'North America',
          'North Asia',
          'North-East Asia',
          'South-East Asia',
          'South Asia',
          'Turkey - Middle East',
        ],
      },
      manufacturer: {
        label: 'Manufacturer',
        default: '',
        type: 'string',
      },
      manufacturer_location: {
        label: 'Manufacturing location',
        default: '',
        type: 'string',
      },
      rsp: {
        label: 'RSP (€)',
        default: '',
        type: 'float',
        options: {
          renderAs: 'price',
          decimals: 2,
        },
      },
      rsp_source: {
        label: 'RSP source',
        default: '',
        type: 'string',
      },
      pack_size_volume: {
        label: 'Pack size volume (liter)',
        default: '',
        type: 'float',
      },
      pack_size_weight: {
        label: 'Pack size weight (kg)',
        default: '',
        type: 'float',
      },

      cost_per_volume: {
        label: 'Cost per liter (€/liter)',
        default: '',
        type: 'computed',
        options: {
          renderAs: 'price',
          decimals: 2,
        },
      },
      cost_per_weight: {
        label: 'Cost per kg (€/kg)',
        default: '',
        type: 'computed',
        options: {
          renderAs: 'price',
          decimals: 2,
        },
      },
      cost_per_wash: {
        label: 'Cost per wash (€/wash)',
        default: '',
        type: 'computed',
        options: {
          renderAs: 'price',
          decimals: 3,
        },
      },
      pack_material: {
        label: 'Pack material',
        default: '',
        type: 'string',
      },
      number_of_washes: {
        label: 'Number of washes',
        default: '',
        type: 'float',
      },
      dosage_per_wash_volume: {
        label: 'Dosage per wash (ml)',
        default: '',
        type: 'computed',
        options: {
          renderAs: 'fixed',
          decimals: 1,
        },
      },
      dosage_per_wash_weight: {
        label: 'Dosage per wash (g)',
        default: '',
        type: 'computed',
        options: {
          renderAs: 'fixed',
          decimals: 1,
        },
      },
      wash_volume: {
        label: 'Wash volume (l)',
        default: '',
        type: 'float',
      },
      concentration_weight: {
        label: 'Product concentration (g/l)',
        default: '',
        type: 'computed',
        options: {
          renderAs: 'fixed',
          decimals: 2,
        },
      },
      concentration_volume: {
        label: 'Product concentration (ml/l)',
        default: '',
        type: 'computed',
        options: {
          renderAs: 'fixed',
          decimals: 2,
        },
      },
      ean_barcode: {
        label: 'EAN bar code',
        default: '',
        type: 'string',
      },
      ufi_code: {
        label: 'UFI code',
        default: '',
        type: 'string',
      },
      retailer_code: {
        label: 'Retailer code',
        default: '',
        type: 'string',
      },
      comments: {
        label: 'Comments',
        default: '',
        type: 'string',
      },
      date_entered: {
        label: 'Date entered',
        default: '',
        type: 'date',
      },
      website_ingredients_list: {
        label: 'Website ingredient list',
        default: '',
        type: 'string',
      },
      website_retailer: {
        label: 'Website retailer info',
        default: '',
        type: 'string',
      },
      website_sds: {
        label: 'Website SDS info',
        default: '',
        type: 'string',
      },
      formulation_id: {
        label: 'Unique Formulation ID',
        default: '',
        type: 'string',
      },
      date_first_production: {
        label: 'Date first production',
        default: '',
        type: 'date',
      },
      date_last_production: {
        label: 'Date last production',
        default: '',
        type: 'date',
      },
      date_ingredient_list: {
        label: 'Date Ingredient list',
        default: '',
        type: 'date',
        options: [
          2018,
          2019,
          2020,
          2021
        ]
      },
      date_sds: {
        label: 'Date SDS',
        default: '',
        type: 'date',
      },
      annual_volume: {
        label: 'Annual volume (tons per annum)',
        default: '',
        type: 'float',
      },
      appearance: {
        label: 'Appearance',
        default: '',
        type: 'string',
      },
      ph_min: {
        label: 'pH min',
        default: '',
        type: 'float',
      },
      ph_max: {
        label: 'pH max',
        default: '',
        type: 'float',
      },
      viscosity_min: {
        label: 'Viscosity min (mPas - mm2/s)',
        default: '',
        type: 'float',
      },
      viscosity_max: {
        label: 'Viscosity max (mPas - mm2/s)',
        default: '',
        type: 'float',
      },
      density_min: {
        label: 'Specific density min (g/ml)',
        default: '',
        type: 'float',
      },
      density_max: {
        label: 'Specific density max (g/ml)',
        default: '',
        type: 'float',
      },
      voc: {
        label: 'VOC (%)',
        default: '',
        type: 'float',
      },
      technical_name: {
        label: 'Technical product name SDS',
        default: '',
        type: 'string',
      },
      status: {
        label: 'Formulation status',
        default: '',
        type: 'select',
        options: [
          'Actual',
          'Historic',
        ]
      },
      sustainability_description: {
        default: '',
        label: 'Sustainability',
        type: 'string',
      },
      claim: {
        default: '',
        label: 'Claims',
        type: 'string',
      },
      formulas: {
        label: '',
        name: 'product.formulas',
        type: 'subform',
        width: {
          sm: 12,
        },
        empty: {
          id: '',
          ranking: null,
          description: '',
          wt_website: '',
          wt_label: '',
          wt_frames: '',
          ingredient: {
            id: '',
            name: '',
          },
        },
        fields: {
          ingredient: {
            label: '',
            default: '',
            type: 'ingredients',
            width: {
              sm: 12,
            },
          },
          ranking: {
            label: 'Ranking',
            default: '',
            type: 'integer',
          },
          percentage_min_sds: {
            label: '',
            default: 'SDS min %',
            type: 'float',
          },
          percentage_max_sds: {
            label: 'SDS max %',
            default: '',
            type: 'float',
          },
          percentage_min_label: {
            label: 'Label min %',
            default: '',
            type: 'float',
          },
          percentage_max_label: {
            label: 'Label max %',
            default: '',
            type: 'float',
          },
          percentage_frames: {
            label: 'FRAMES % estimate',
            default: '',
            type: 'float',
          },
        },
      },
    },
  },
};

export default json;
