import React from 'react';
import PropTypes from 'prop-types';
import { isNumber } from '../../scripts/utils';


export function formatPrice({ value,  unit= '',
  currency= 'EUR',
  language= 'EUR',
  decimals= 2,
  empty= ''}) {
  const formatter = new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  if (isNumber(value) && value > 0) {
        return `${formatter.format(value)}${unit || ''}`
  }
  if (empty!==null) {
    return empty
  }
  return null;
}

function Price({ value, empty, unit, currency, language, decimals }) {
  const price = formatPrice({ value, empty, unit, currency, language, decimals }) 

  if (price!==null)
    return (
      <>
        {price}
      </>
    );
  return null;
}

Price.defaultProps = {
  value: null,
  unit: null,
  currency: 'EUR',
  language: 'EUR',
  decimals: 2,
  empty: null,
};

Price.propTypes = {
  value: PropTypes.number,
  decimals: PropTypes.number,
  empty: PropTypes.string,
  unit: PropTypes.string,
  currency: PropTypes.string,
  language: PropTypes.string,
};

export default Price;
