/* eslint-disable camelcase */
import gql from 'graphql-tag';

export const PRODUCT_ATTRIBUTES = gql`
  fragment productAttributes on product {
    id
    created_at
    market
    category
    subcategory
    format
    subformat
    brand_name
    variant_name
    brand_owner
    country
    region
    manufacturer
    manufacturer_location
    rsp

    cost_per_volume
    cost_per_weight
    cost_per_wash

    rsp_source
    pack_size_volume
    pack_size_weight
    pack_material
    number_of_washes
    dosage_per_wash_volume
    dosage_per_wash_weight
    wash_volume
    concentration_weight
    concentration_volume
    ean_barcode
    ufi_code
    retailer_code
    comments
    date_entered
    website_ingredients_list
    website_retailer
    website_sds
    formulation_id
    date_first_production
    date_last_production
    date_ingredient_list
    date_sds
    appearance
    annual_volume
    ph_min
    ph_max
    viscosity_min
    viscosity_max
    density_min
    density_max
    voc
    technical_name
    sustainability_description
    claim
    status
    file {
      id
      path
    }
    ingredient_count {
      count
      ranking
    }
  }
`;

export const INGREDIENT_ATTRIBUTES = gql`
  fragment ingredientAttributes on ingredient {
    id
    name
    cas
    function
    type
    class_name
    price_source
    format
    ec_number
    mol_format
    mol_weight
    sustainability_description
    sustainability_rating
    volume
    volume_description
  }
`;

export const FORMULA_ATTRIBUTES = gql`
  fragment formulaAttributes on formula {
    id
    ranking
    product_id
  }
`;

export const MATERIAL_ATTRIBUTES = gql`
  fragment materialAttributes on material {
    id
    trade_name
    description
    format
    percentage_active
    price_raw
    price_type
    delivery_mode
    production_location
    production_country
    production_region
    reach_registration_number
    us_registration_number
    date
    comments
    website
    sustainability_description
    msds
    supplier {
      id
      name
    }
    ingredient {
      id
      name
    }
  }
`;

export const SUPPLIER_ATTRIBUTES = gql`
  fragment supplierAttributes on supplier {
    id
    name
  }
`;

export const FILE_ATTRIBUTES = gql`
  fragment fileAttributes on file {
    id
    name
    type
    description
    extension
    path
  }
`;

export const GET_PRODUCTS = ({ with_price_active }) => {
  return gql`
    query products(
      $offset: Int!
      $limit: Int!
      $filter: product_bool_exp
    ) {
      allItems: product_aggregate(where: $filter) {
        aggregate {
          count
        }
      }
      products: product(
        limit: $limit
        offset: $offset
        where: $filter
        order_by: {
          brand_name: asc
          brand_owner: asc
          variant_name: asc
          country: asc
        }
      ) {
        completeness
        ...productAttributes
        ${with_price_active ? 'price_active' : ''}
      }
    }
    ${PRODUCT_ATTRIBUTES}
  `;
};

export const GET_PRODUCT = ({ with_price_active, with_percentage }) => gql`
  query product(
    $ids: [Int!]
  ) {
    product(where: { id: { _in: $ids } }) {
      ...productAttributes

      totals(order_by: {
          ${with_percentage ? 'percentage_frames: desc' : 'sort_order: desc'}
        }) {
        type
        class_name
        depth
        sort_order
        ${with_price_active ? 'price_active' : ''}
        ${
          with_percentage
            ? 'percentage_min_sds percentage_max_sds percentage_min_label percentage_max_label percentage_frames'
            : ''
        }
      }

      formulas(order_by: {
          ${
            with_percentage
              ? 'ranking: asc, percentage_frames: desc'
              : 'ranking: asc'
          }
         }) {
        ...formulaAttributes
        ${
          with_percentage
            ? 'percentage_min_sds percentage_max_sds percentage_min_label percentage_max_label percentage_frames'
            : ''
        }
        ingredient {
          ...ingredientAttributes
          ${with_price_active ? 'price_active' : ''}
          materials {
            ...materialAttributes
          }
        }
      }
    }
  }
  ${PRODUCT_ATTRIBUTES}
  ${INGREDIENT_ATTRIBUTES}
  ${FORMULA_ATTRIBUTES}
  ${MATERIAL_ATTRIBUTES}
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $id: Int!
    $market: String
    $category: String
    $subcategory: String
    $format: String
    $subformat: String
    $brand_name: String
    $variant_name: String
    $brand_owner: String
    $country: String
    $region: String
    $manufacturer: String
    $manufacturer_location: String
    $rsp: float8
    $rsp_source: String
    $pack_size_volume: float8
    $pack_size_weight: float8
    $pack_material: String
    $number_of_washes: float8
    $wash_volume: float8
    $ean_barcode: String
    $ufi_code: String
    $retailer_code: String
    $comments: String
    $date_entered: date
    $website_ingredients_list: String
    $website_retailer: String
    $website_sds: String
    $formulation_id: String
    $date_first_production: date
    $date_last_production: date
    $date_ingredient_list: date
    $date_sds: date
    $appearance: String
    $annual_volume: float8
    $ph_min: float8
    $ph_max: float8
    $viscosity_min: float8
    $viscosity_max: float8
    $density_min: float8
    $density_max: float8
    $voc: float8
    $technical_name: String
    $sustainability_description: String
    $claim: String
    $status: String
    $file: Int
  ) {
    product: update_product(
      where: { id: { _eq: $id } }
      _set: {
        market: $market
        category: $category
        subcategory: $subcategory
        format: $format
        subformat: $subformat
        brand_name: $brand_name
        variant_name: $variant_name
        brand_owner: $brand_owner
        country: $country
        region: $region
        manufacturer: $manufacturer
        manufacturer_location: $manufacturer_location
        rsp: $rsp
        rsp_source: $rsp_source
        pack_size_volume: $pack_size_volume
        pack_size_weight: $pack_size_weight
        pack_material: $pack_material
        number_of_washes: $number_of_washes
        wash_volume: $wash_volume
        ean_barcode: $ean_barcode
        ufi_code: $ufi_code
        retailer_code: $retailer_code
        comments: $comments
        date_entered: $date_entered
        website_ingredients_list: $website_ingredients_list
        website_retailer: $website_retailer
        website_sds: $website_sds
        formulation_id: $formulation_id
        date_first_production: $date_first_production
        date_last_production: $date_last_production
        date_ingredient_list: $date_ingredient_list
        date_sds: $date_sds
        appearance: $appearance
        annual_volume: $annual_volume
        ph_min: $ph_min
        ph_max: $ph_max
        viscosity_min: $viscosity_min
        viscosity_max: $viscosity_max
        density_min: $density_min
        density_max: $density_max
        voc: $voc
        technical_name: $technical_name
        sustainability_description: $sustainability_description
        claim: $claim
        status: $status
        file_id: $file
      }
    ) {
      affected_rows
      product: returning {
        id
      }
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct(
    $market: String
    $category: String
    $subcategory: String
    $format: String
    $subformat: String
    $brand_name: String
    $variant_name: String
    $brand_owner: String
    $country: String
    $region: String
    $manufacturer: String
    $manufacturer_location: String
    $rsp: float8
    $rsp_source: String
    $pack_size_volume: float8
    $pack_size_weight: float8
    $pack_material: String
    $number_of_washes: float8
    $wash_volume: float8
    $ean_barcode: String
    $ufi_code: String
    $retailer_code: String
    $comments: String
    $date_entered: date
    $website_ingredients_list: String
    $website_retailer: String
    $website_sds: String
    $formulation_id: String
    $date_first_production: date
    $date_last_production: date
    $date_ingredient_list: date
    $date_sds: date
    $appearance: String
    $annual_volume: float8
    $ph_min: float8
    $ph_max: float8
    $viscosity_min: float8
    $viscosity_max: float8
    $density_min: float8
    $density_max: float8
    $voc: float8
    $technical_name: String
    $sustainability_description: String
    $claim: String
    $status: String
    $formulas: formula_arr_rel_insert_input
    $file: Int
  ) {
    product: insert_product(
      objects: {
        market: $market
        category: $category
        subcategory: $subcategory
        format: $format
        subformat: $subformat
        brand_name: $brand_name
        variant_name: $variant_name
        brand_owner: $brand_owner
        country: $country
        region: $region
        manufacturer: $manufacturer
        manufacturer_location: $manufacturer_location
        rsp: $rsp
        rsp_source: $rsp_source
        pack_size_volume: $pack_size_volume
        pack_size_weight: $pack_size_weight
        pack_material: $pack_material
        number_of_washes: $number_of_washes
        wash_volume: $wash_volume
        ean_barcode: $ean_barcode
        ufi_code: $ufi_code
        retailer_code: $retailer_code
        comments: $comments
        date_entered: $date_entered
        website_ingredients_list: $website_ingredients_list
        website_retailer: $website_retailer
        website_sds: $website_sds
        formulation_id: $formulation_id
        date_first_production: $date_first_production
        date_last_production: $date_last_production
        date_ingredient_list: $date_ingredient_list
        date_sds: $date_sds
        appearance: $appearance
        annual_volume: $annual_volume
        ph_min: $ph_min
        ph_max: $ph_max
        viscosity_min: $viscosity_min
        viscosity_max: $viscosity_max
        density_min: $density_min
        density_max: $density_max
        voc: $voc
        technical_name: $technical_name
        sustainability_description: $sustainability_description
        claim: $claim
        status: $status
        formulas: $formulas
        file_id: $file
      }
    ) {
      product: returning {
        id
      }
    }
  }
`;

export const GET_FORMULA = ({ with_percentage }) => {
  return gql`
  query formula($id: Int!, $product_id: Int!) {
    formula(
      where: {
        _and: [{ id: { _eq: $id } }, { product_id: { _eq: $product_id } }]
      }
    ) {
      ...formulaAttributes
      ${
        with_percentage
          ? 'percentage_min_sds percentage_max_sds percentage_min_label percentage_max_label percentage_frames'
          : ''
      }
      ingredient {
        id
        value: id
        label: name
      }
      product {
        id
      }
    }
  }
  ${FORMULA_ATTRIBUTES}
`;
};

export const UPDATE_FORMULA = gql`
  mutation updateFormula(
    $id: Int!
    $product_id: Int!
    $ranking: Int
    $ingredient: Int!
    $percentage_min_sds: float8
    $percentage_max_sds: float8
    $percentage_min_label: float8
    $percentage_max_label: float8
    $percentage_frames: float8
  ) {
    formula: update_formula(
      where: {
        _and: [{ id: { _eq: $id } }, { product_id: { _eq: $product_id } }]
      }
      _set: {
        ranking: $ranking
        ingredient_id: $ingredient
        percentage_min_sds: $percentage_min_sds
        percentage_max_sds: $percentage_max_sds
        percentage_min_label: $percentage_min_label
        percentage_max_label: $percentage_max_label
        percentage_frames: $percentage_frames
      }
    ) {
      affected_rows
      formula: returning {
        id
        product_id
      }
    }
  }
`;

export const CREATE_FORMULA = gql`
  mutation createFormula(
    $product_id: Int!
    $ingredient: Int!
    $ranking: Int
    $percentage_min_sds: float8
    $percentage_max_sds: float8
    $percentage_min_label: float8
    $percentage_max_label: float8
    $percentage_frames: float8
  ) {
    formula: insert_formula(
      objects: {
        product_id: $product_id
        ingredient_id: $ingredient
        ranking: $ranking
        percentage_min_sds: $percentage_min_sds
        percentage_max_sds: $percentage_max_sds
        percentage_min_label: $percentage_min_label
        percentage_max_label: $percentage_max_label
        percentage_frames: $percentage_frames
      }
    ) {
      formula: returning {
        id
        product_id
      }
    }
  }
`;

export const UPDATE_FORMULA_INGREDIENT = gql`
  mutation updateFormula($id: Int!, $ingredient: ID!) {
    updateFormula(
      input: { where: { id: { _eq: $id } }, data: { ingredient: $ingredient } }
    ) {
      formula: returning {
        id
        product_id
      }
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: Int!) {
    formula: delete_product(where: { id: { _eq: $id } }) {
      formula: returning {
        id
      }
    }
  }
`;

export const DELETE_INGREDIENT = gql`
  mutation deleteIngredient($id: Int!) {
    formula: delete_ingredient(where: { id: { _eq: $id } }) {
      formula: returning {
        id
      }
    }
  }
`;

export const DELETE_FORMULA = gql`
  mutation deleteFormula($id: Int!) {
    formula: delete_formula(where: { id: { _eq: $id } }) {
      formula: returning {
        id
        product_id
      }
    }
  }
`;

export const DELETE_MATERIAL = gql`
  mutation deleteMaterial($id: Int!) {
    formula: delete_material(where: { id: { _eq: $id } }) {
      formula: returning {
        id
      }
    }
  }
`;

export const SELECT_INGREDIENTS = gql`
  query ingredients($name: String) {
    options: ingredient(
      order_by: { name: asc }
      where: { name: { _ilike: $name } }
    ) {
      value: id
      label: name
    }
  }
`;

export const FILTER_INGREDIENTS = gql`
  query ingredients($offset: Int!, $limit: Int!, $name: String) {
    options: ingredient(
      order_by: { name: asc }
      limit: $limit
      offset: $offset
      where: { name: { _ilike: $name } }
    ) {
      value: id
      label: name
    }
  }
`;

export const SELECT_INGREDIENT_SYNONYMS = gql`
  query ingredients {
    ingredients: ingredient_view(order_by: { ingredient_id: asc }) {
      ingredient_id
      name
      stripped_name
    }
  }
`;

export const SELECT_SUPPLIERS = gql`
  query suppliers($name: String) {
    options: supplier(
      order_by: { name: asc }
      where: { name: { _ilike: $name } }
    ) {
      value: id
      label: name
    }
  }
  ${SUPPLIER_ATTRIBUTES}
`;

export const GET_INGREDIENTS = ({ with_price_active }) => {
  return gql`
    query ingredients(
      $offset: Int!
      $limit: Int!
      $filter: ingredient_bool_exp
    ) {
      allItems: ingredient_aggregate(where: $filter) {
        aggregate {
          count
        }
      }
      ingredients: ingredient(
        limit: $limit
        offset: $offset
        where: $filter
        order_by: { name: asc, type: asc, cas: asc, ec_number: asc }
      ) {
        ...ingredientAttributes
        ${with_price_active ? 'price_active' : ''}
      }
    }
    ${INGREDIENT_ATTRIBUTES}
  `;
};

export const GET_INGREDIENT = ({ with_price_active }) => {
  return gql`
  query ingredient($id: Int!) {
    ingredient(where: { id: { _eq: $id } }) {
      ...ingredientAttributes
      ${with_price_active ? 'price_active' : ''}
      formulas (order_by: {product: {category: asc, subcategory: asc, format: asc, subformat: asc, brand_name: asc, variant_name: asc, brand_owner: asc, country: asc}}) {
        id
        product {
          id
          market
          category
          subcategory
          format
          subformat
          brand_name
          variant_name
          brand_owner
          country
          region
        }
      }
      materials {
        ...materialAttributes
      }
    }
  }
  ${INGREDIENT_ATTRIBUTES}
  ${MATERIAL_ATTRIBUTES}
`;
};

export const UPDATE_INGREDIENT = gql`
  mutation updateIngredient(
    $id: Int!
    $name: String!
    $cas: String
    $function: String
    $type: String
    $class_name: String
    $price_active: float8
    $price_source: String
    $format: String
    $ec_number: String
    $mol_format: String
    $mol_weight: float8
    $sustainability_description: String
    $sustainability_rating: String
    $volume: float8
    $volume_description: String
  ) {
    ingredient: update_ingredient(
      where: { id: { _eq: $id } }
      _set: {
        name: $name
        cas: $cas
        function: $function
        type: $type
        class_name: $class_name
        price_active: $price_active
        price_source: $price_source
        format: $format
        ec_number: $ec_number
        mol_format: $mol_format
        mol_weight: $mol_weight
        sustainability_description: $sustainability_description
        sustainability_rating: $sustainability_rating
        volume: $volume
        volume_description: $volume_description
      }
    ) {
      affected_rows
      ingredient: returning {
        id
      }
    }
  }
`;

export const CREATE_INGREDIENT = gql`
  mutation createIngredient(
    $name: String!
    $cas: String
    $function: String
    $type: String
    $class_name: String
    $price_active: float8
    $price_source: String
    $format: String
    $ec_number: String
    $mol_format: String
    $mol_weight: float8
    $sustainability_description: String
    $sustainability_rating: String
    $volume: float8
    $volume_description: String
  ) {
    ingredient: insert_ingredient(
      objects: {
        name: $name
        cas: $cas
        function: $function
        type: $type
        class_name: $class_name
        price_active: $price_active
        price_source: $price_source
        format: $format
        ec_number: $ec_number
        mol_format: $mol_format
        mol_weight: $mol_weight
        sustainability_description: $sustainability_description
        sustainability_rating: $sustainability_rating
        volume: $volume
        volume_description: $volume_description
      }
    ) {
      ingredient: returning {
        id
      }
    }
  }
`;

export const GET_MATERIALS = gql`
  query materials($offset: Int!, $limit: Int!, $filter: material_bool_exp) {
    allItems: material_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
    materials: material(limit: $limit, offset: $offset, where: $filter) {
      ...materialAttributes
    }
  }
  ${MATERIAL_ATTRIBUTES}
`;

export const GET_MATERIAL = gql`
  query material($id: Int!) {
    material(where: { id: { _eq: $id } }) {
      ...materialAttributes
      ingredient {
        id
        value: id
        label: name
        price_active
      }
      supplier {
        id
        value: id
        label: name
      }
    }
  }
  ${MATERIAL_ATTRIBUTES}
`;

export const UPDATE_MATERIAL = gql`
  mutation updateMaterial(
    $id: Int!
    $trade_name: String!
    $description: String
    $format: String
    $percentage_active: float8
    $price_raw: float8
    $price_type: String
    $delivery_mode: String
    $production_location: String
    $production_country: String
    $production_region: String
    $reach_registration_number: String
    $us_registration_number: String
    $date: date
    $comments: String
    $website: String
    $sustainability_description: String
    $msds: String
    $ingredient: Int!
    $supplier: Int!
  ) {
    material: update_material(
      where: { id: { _eq: $id } }
      _set: {
        trade_name: $trade_name
        description: $description
        format: $format
        percentage_active: $percentage_active
        price_raw: $price_raw
        price_type: $price_type
        delivery_mode: $delivery_mode
        production_location: $production_location
        production_country: $production_country
        production_region: $production_region
        reach_registration_number: $production_region
        us_registration_number: $production_region
        date: $date
        comments: $comments
        website: $website
        sustainability_description: $sustainability_description
        msds: $msds
        ingredient_id: $ingredient
        supplier_id: $supplier
      }
    ) {
      affected_rows
      material: returning {
        id
      }
    }
  }
`;

export const CREATE_MATERIAL = gql`
  mutation createMaterial(
    $trade_name: String!
    $description: String
    $format: String
    $percentage_active: float8
    $price_raw: float8
    $price_type: String
    $delivery_mode: String
    $production_location: String
    $production_country: String
    $production_region: String
    $reach_registration_number: String
    $us_registration_number: String
    $date: date
    $comments: String
    $website: String
    $sustainability_description: String
    $msds: String
    $ingredient: Int!
    $supplier: Int!
  ) {
    material: insert_material(
      objects: {
        trade_name: $trade_name
        description: $description
        format: $format
        percentage_active: $percentage_active
        price_raw: $price_raw
        price_type: $price_type
        delivery_mode: $delivery_mode
        production_location: $production_location
        production_country: $production_country
        production_region: $production_region
        reach_registration_number: $production_region
        us_registration_number: $production_region
        date: $date
        comments: $comments
        website: $website
        sustainability_description: $sustainability_description
        msds: $msds
        ingredient_id: $ingredient
        supplier_id: $supplier
      }
    ) {
      material: returning {
        id
      }
    }
  }
`;

export const UPDATE_PRICE = gql`
  mutation updatePrice(
    $id: Int!
    $ingredient: ID!
    $price_raw: float8
    $price_active: float8
    $active: float8
  ) {
    updatePrice(
      input: {
        where: { id: { _eq: $id } }
        data: {
          ingredient: $ingredient
          price_raw: $price_raw
          price_active: $price_active
          active: $active
        }
      }
    ) {
      price {
        id
      }
    }
  }
`;

export const CREATE_PRICE = gql`
  mutation createPrice(
    $ingredient: ID!
    $price_raw: float8
    $price_active: float8
    $active: float8
  ) {
    createPrice(
      input: {
        data: {
          ingredient: $ingredient
          price_raw: $price_raw
          price_active: $price_active
          active: $active
        }
      }
    ) {
      price {
        id
      }
    }
  }
`;

export const DELETE_PRICE = gql`
  mutation deletePrice($id: Int!) {
    deletePrice(where: { id: { _eq: $id } }) {
      price {
        id
      }
    }
  }
`;

export const UPDATE_PRICE_SUPPLIER = gql`
  mutation updatePrice($id: Int!, $supplier: ID!) {
    updatePrice(
      input: { where: { id: { _eq: $id } }, data: { supplier: $supplier } }
    ) {
      price {
        id
      }
    }
  }
`;

export const GET_SUPPLIERS = gql`
  query {
    options: suppliers {
      id
      name
    }
  }
`;

export const GET_PRODUCT_COUNTS = gql`
  query product_group_count {
    group_count: product_group_count {
      count
      group
      value
      order
    }
  }
`;

export const GET_INGREDIENT_COUNTS = gql`
  query ingredient_group_count {
    group_count: ingredient_group_count {
      count
      group
      value
      order
    }
  }
`;

export const GET_MATERIAL_COUNTS = gql`
  query material_group_count {
    group_count: material_group_count {
      count
      group
      value
      order
    }
  }
`;

export const GET_PRODUCT_COUNTS_FUNCTION = gql`
  query product_group_count(
    $brand_name: _varchar!
    $brand_owner: _varchar!
    $market: _varchar!
    $category: _varchar!
    $country: _varchar!
    $format: _varchar!
    $manufacturer: _varchar!
    $region: _varchar!
    $subcategory: _varchar!
    $subformat: _varchar!
    $variant_name: _varchar!
  ) {
    product_group_count: product_count(
      args: {
        brand_names: $brand_name
        brand_owners: $brand_owner
        markets: $markets
        categories: $category
        countries: $country
        formats: $format
        manufacturers: $manufacturer
        regions: $region
        subcategories: $subcategory
        subformats: $subformat
        variant_names: $variant_name
      }
    ) {
      group
      value
      count
    }
  }
`;

export const CREATE_PRODUCT_WITH_FORMULAS = gql`
  mutation createProduct($variant_name: String) {
    product: insert_product(objects: { variant_name: $variant_name }) {
      product: returning {
        id
      }
    }
  }
`;

export const BULK_INSERT_SUPPLIERS = gql`
  mutation createSuppliers($suppliers: [supplier_insert_input!]!) {
    supplier: insert_supplier(objects: $suppliers) {
      affected_rows
    }
  }
`;

export const BULK_INSERT_INGREDIENT_SYNONYMS = gql`
  mutation createSynonyms($synonyms: [ingredient_synonym_insert_input!]!) {
    ingredient_synonym: insert_ingredient_synonym(
      objects: $synonyms
      on_conflict: {
        constraint: ingredient_synonym_name_key
        update_columns: [name, stripped_name]
      }
    ) {
      affected_rows
    }
  }
`;

export const BULK_INSERT_PRODUCTS = gql`
  mutation createProducts($products: [product_insert_input!]!) {
    product: insert_product(objects: $products) {
      affected_rows
    }
  }
`;

export const BULK_INSERT_INGREDIENTS = gql`
  mutation createIngredients($ingredients: [ingredient_insert_input!]!) {
    ingredient: insert_ingredient(
      objects: $ingredients
      on_conflict: {
        constraint: ingredient_name_key
        update_columns: [price_active, cas, type, class_name]
      }
    ) {
      affected_rows
    }
  }
`;

export const BULK_INSERT_FILES = gql`
  mutation createFiles($files: [file_insert_input!]!) {
    file: insert_file(objects: $files) {
      affected_rows
    }
  }
`;

/*
mutation {
  insert_ingredient_synonym (
    objects: {name: "Test2",
      ingredient: {
        data: {
          name: "MEA LAURETH SULFATE"
        }
        on_conflict: {
          constraint: ingredient_name_key,
          update_columns: [name]
        }
      }
    }
  ) {
    affected_rows
  }
}

  mutation createSynonyms($synonyms: [ingredient_synonym_insert_input!]!) {
    supplier: insert_ingredient_synonym(
      objects: $synonyms, on_conflict: {constraint: ingredient_synonym_name_key}

    {
      affected_rows
    }
  }

{
  "synonyms": [{"name": "Test21", "ingredient": {"data": {"name": "AQUA"}, "on_conflict": {"constraint": "ingredient_name_key", "update_columns": ["name"]}}}, { "name": "Test31", "ingredient": {"data": {"name": "AQUA"}} }]
}

  mutation createSynonyms($synonyms: [ingredient_synonym_insert_input!]!) {
    supplier: insert_ingredient_synonym(
      objects: $synonyms)

    {

      affected_rows
    }
  }

  {"synonyms": [{"name": "Test3",
      "ingredient": {
        "data": {
          "name": "MEA LAURETH SULFATE"
        },
        "on_conflict": {
          "constraint": "ingredient_name_key",
          "update_columns": ["name"]
        }
      }
}]}

*/

export const GET_FILES = gql`
  query files($offset: Int!, $limit: Int!, $filter: file_bool_exp) {
    allItems: file_aggregate(where: $filter) {
      aggregate {
        count
      }
    }
    files: file(
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
      where: $filter
    ) {
      ...fileAttributes
    }
  }
  ${FILE_ATTRIBUTES}
`;

export const CREATE_FILE = gql`
  mutation($formData: String!) {
    fileUpload(formData: $formData) {
      path
    }
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFile($id: Int!) {
    formula: delete_file(where: { id: { _eq: $id } }) {
      formula: returning {
        id
      }
    }
  }
`;

export const SELECT_FILES = gql`
  query files($name: String) {
    options: file(order_by: { name: asc }, where: { name: { _ilike: $name } }) {
      ...fileAttributes
    }
  }
  ${FILE_ATTRIBUTES}
`;

export const SELECT_CATEGORY = gql`
  query category($name: String) {
    options: category(
      order_by: { name: asc }
      where: { name: { _ilike: $name } }
    ) {
      value: name
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory($name: String!) {
    options: insert_category(objects: { name: $name }) {
      options: returning {
        id
      }
    }
  }
`;

export const GET_FORMULA_SYNC = gql`
  query formulas($source_product_id: Int!, $destination_product_id: Int!) {
    formula(where: { product_id: { _eq: $destination_product_id } }) {
      id
      product_id

      product {
        brand_name
      }
      ingredient_id
      percentage_frames
      percentage_max_label
      percentage_max_sds
      percentage_min_label
      percentage_min_sds
      ingredient {
        id
        name
        formulas(where: { product_id: { _eq: $source_product_id } }) {
          id
          product_id
          product {
            brand_name
          }
          ingredient_id
          percentage_frames
          percentage_max_label
          percentage_max_sds
          percentage_min_label
          percentage_min_sds
        }
      }
    }
  }
`;
