import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Percentage from '../Percentage';
import { getTotals } from '../../scripts/utils';
import Price from '../Price';
import Can from '../Can';
import ExportXLS from '../../components/ExportXLS';
import layout from '../../config/product-layout';

const display = layout.product;

const styles = () => ({
  button: {
    margin: 'theme.spacing(1)px 0',
  },
  tableHead: {
    whiteSpace: 'nowrap',
  },
  headCell: {
    whiteSpace: 'nowrap',
  },

  valueCell: {},
  rowDepth1: {
    '& td, & th': {
      border: 0,
      borderTop: '1px solid #A5D8DA',
      fontWeight: 'bold',
    },
  },
  rowDepth2: {
    '& td, & th': {
      border: 0,
      borderTop: '1px solid #A5D8DA',
    },
  },
  rowDepth3: {
    '& td, & th': {
      paddingTop: 4,
      paddingBottom: 4,
      border: 0,
    },
  },
  depth1: {},
  depth2: {},
  depth3: {
    paddingLeft: 10,
    fontSize: 13,
  },
});

const StyledPrice = withStyles(() => ({
  textAlign: 'right',
}))(Price);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      // backgroundColor: 'rgba(165, 216, 218, 0.15)',
    },
    '&:last-of-type': {
      backgroundColor: 'rgba(165, 216, 218, 0.15)',
      border: 0,
      borderBottom: '1px solid #A5D8DA',
    },
  },
}))(TableRow);

function ProductTotals(props) {
  const { classes, products } = props;
  const formulations = getTotals(products);

  formulations.forEach(formulas =>
    formulas.forEach(formula => (
      products.forEach((product, index) => {
          const total = product.totals.find(
            t =>
              t.class_name === formula.class_name &&
              t.type === formula.type &&
              t.depth === formula.depth,
          );

          const key = `${formula.class_name}-${formula.type}-${formula.depth}`;
          const productTotal = {
            [`${key}-price_active`]: total && total.price_active ? total.price_active : '-',
            [`${key}-percentage_frames`]: total && total.percentage_frames ? total.percentage_frames : '-'
          }
          const newProduct = {
            ...product,
            ...productTotal
          }
          products[index]=newProduct;
          console.log('product', products[index], index, total)
          //products[index][`${key}-percentage_frames`] = total && total.percentage_frames ? total.percentage_frames : '-'
          //products[index][key][`${key}-price_active`] = total && total.price_active ? total.price_active : '-'
      }))));

  return (
    <>
      <TableRow>
        <TableCell
          colSpan={products.length + 1}
          className={classes.headerCell}
        >
          <h3>Overview</h3>
          <ExportXLS
            data={products}
            fields={display.fields}
            button="Export"
          />
        </TableCell>
      </TableRow>
      {formulations.map(formulas =>
        formulas.map(formula => (
          <StyledTableRow
            key={`${formula.class_name}-${formula.type}-${formula.depth}`}
            className={classes[`rowDepth${formula.depth}`]}
          >
            <TableCell component="th" scope="row" className={classes.headCell}>
              <div className={classes[`depth${formula.depth}`]}>
                {formula.type}
              </div>
            </TableCell>
            {products.map(product => {
              const total = product.totals.find(
                t =>
                  t.class_name === formula.class_name &&
                  t.type === formula.type &&
                  t.depth === formula.depth,
              );
              return (
                <TableCell
                  className={classes.valueCell}
                  key={`${formula.class_name}-${formula.type}-${formula.depth}-${product.id}`}
                >
                  <div className={classes[`depth${formula.depth}`]}>
                    {total && total.percentage_frames ? (
                      <Can
                        perform="product.field.percentage_frames"
                        yes={() => (
                          <>
                            <Percentage value={total.percentage_frames} />%
                          </>
                        )}
                      />
                    ) : (
                      '-'
                    )}
                    {total && total.price_active ? (
                      <Can
                        perform="product.field.price_active"
                        yes={() => (
                          <>
                            {' / '}
                            <StyledPrice
                              value={total.price_active}
                              decimals={0}
                            />
                          </>
                        )}
                      />
                    ) : null}
                  </div>
                </TableCell>
              );
            })}
          </StyledTableRow>
        )),
      )}
    </>
  );
}

ProductTotals.defaultProps = {};

ProductTotals.propTypes = {
  classes: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
};

export default withStyles(styles)(ProductTotals);
