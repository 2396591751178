import React from 'react';
import PropTypes from 'prop-types';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@material-ui/core';

const exportToCSV = (data, fields, fileName) => {

  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  XLSX.writeFile(wb, 'sheetjs.xlsx');

    /*

  const transp = a =>
    Object.keys(a[0]).map(c => {
      const ret = a.map(r => r[c]);
      ret.unshift(c);
      return ret;
    });

  const t = transp(data);

  const ws = XLSX.utils.json_to_sheet(t, { skipHeader: 1 });
  const range = XLSX.utils.decode_range(ws['!ref']);

  for (let { c } = range.s.c; c <= range.e.c; c++) {
    const header = `${XLSX.utils.encode_col(c)}1`;

    if (fields[ws[header].v]) {
      ws[header].v = fields[ws[header].v].label;
    } else {
      console.log('hide', ws, header, c);
      // ws['!cols'][c] = { hidden: true };
    }
  }

  for (let { r } = range.s; r <= range.e.r; r = +1) {
    console.log('r', r);
    const column = XLSX.utils.encode_cell({ c: 0, r });
    console.log('column', column, r);
    if (fields[ws[column].v]) {
      ws[column].v = fields[ws[column].v].label;
    } else {
      console.log('hide', ws, column, r);
      // ws['!cols'][c] = { hidden: true };
    }
  }
  */

  /*
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(blob, fileName + fileExtension);
    XLSX.writeFile(wb, 'sheetjs.xlsx') 

  */

};

export const Export = ({ data, fileName, fields }) => {

  const changeKeyObjects = (arr, replaceKeys) => {
    return arr.map(item => {
      const newItem = {};
      Object.keys(item).forEach(key => {
        const newKey = replaceKeys[key] && replaceKeys[key]?.label ? replaceKeys[key].label : key;
        newItem[newKey] = item[[key]];
      });
      return newItem;
    });
  };

  const ws = changeKeyObjects(data, fields)
  return (
    <Button
      component={Button}
      variant="contained"
      type="button"
      color="primary"
      aria-label="Export"
      onClick={e => {
        e.preventDefault();
        exportToCSV(ws, fields, fileName);
      }}
    >
      Export
    </Button>
  );
};

Export.defaultProps = {
  data: null,
  fileName: 'frames',
  fields: [],
};

Export.propTypes = {
  data: PropTypes.array,
  fileName: PropTypes.string,
  fields: PropTypes.array,
};

export default Export;
