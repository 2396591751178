import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Query } from '@apollo/client/react/components';
import { Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Auth0Context } from '../../hooks/AuthContext';
import history from '../../scripts/history';

import IngredientSummary from '../../components/IngredientSummary';

import LoadingIndicator from '../../components/LoadingIndicator';
import Error from '../../components/Error';

import { GET_INGREDIENT, GET_PRODUCT } from '../../queries';

import Ingredients from '../../components/ProductSections/ingredients';
import Materials from '../../components/ProductSections/materials';
import Summary from '../../components/ProductSections/summary';
import Totals from '../../components/ProductSections/totals';
import Can from '../../components/Can';

import ExportXLS from '../../components/ExportXLS';
import layout from '../../config/product-layout';

const display = layout.product;

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 100,
  },
  image: {
    maxWidth: '100%',
    marginBottom: 50,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    bottom: 20,
    left: 0,
    right: 0,
    margin: '0 auto',
  },

  filterContainer: {
    padding: theme.spacing(2),
    position: 'relative',
    width: 450,
    boxShadow: 'none',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

const handleClose = (props, id) => {
  history.push(`/product/${id}`);
};

function ProductDetailsPage(props) {
  const authContext = useContext(Auth0Context);
  const { user } = authContext;

  const {
    classes,
    match: {
      params: { id },
    },
  } = props;
  const ids = [];
  ids.push(id);

  return (
    <Query
      query={GET_PRODUCT({
        with_price_active:
          (user && user.access && user.access.product_price_active) || false,
        with_percentage:
          (user && user.access && user.access.percentage) || false,
      })}
      variables={{
        ids,
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <LoadingIndicator />;
        }

        if (error) {
          return <Error message={error.message} />;
        }

        if (!data.product || data.product.length <= 0) {
          history.replace(`/products`);
          return <Error message="Error loading product.." />;
        }

        const products = data.product;

        return (
          <>
            <Helmet>
              <title>Product Details Page</title>
              <meta name="description" content="" />
            </Helmet>
            <Route path="/product/:id/ingredient/:iid">
              {({ match }) => {
                const isMatch = match && match.params;
                const iid = match && match.params && match.params.iid;
                return (
                  <Drawer
                    open={!!isMatch}
                    anchor="left"
                    onClose={() => handleClose(props, id)}
                  >
                    <Paper className={classes.filterContainer} elevation={2}>
                      <div className={classes.drawerHeader}>
                        <Link
                          to={`/product/${id}`}
                          onClick={() => handleClose(props, id)}
                          color="primary"
                        >
                          <h3>
                            Close
                            <ChevronRightIcon />
                          </h3>
                        </Link>
                      </div>
                      <h3>Ingredient</h3>
                      {isMatch && id && (
                        <Query
                          query={GET_INGREDIENT({ with_price_active: false })}
                          variables={{ id: iid }}
                        >
                          {({
                            loading: ingredientLoading,
                            error: ingredientError,
                            data: ingredientData,
                          }) => {
                            if (ingredientLoading) {
                              return <LoadingIndicator />;
                            }
                            if (ingredientError) {
                              return <Error message={ingredientError} />;
                            }
                            if (
                              !ingredientData.ingredient ||
                              ingredientData.ingredient.length !== 1
                            ) {
                              return (
                                <Error message="Error loading ingredient..." />
                              );
                            }
                            const ingredient = ingredientData.ingredient[0];

                            return (
                              <IngredientSummary ingredient={ingredient} />
                            );
                          }}
                        </Query>
                      )}
                    </Paper>
                  </Drawer>
                );
              }}
            </Route>

            <Paper className={classes.container} elevation={2}>
              <h3>Product</h3>

              {true   && (
                <ExportXLS
                  data={products}
                  fields={display.fields}
                  button="Export"
                />
              )}

              <Summary products={products} />

              <h3>Overview</h3>
              <Totals products={products} />

              <h3>Ingredients</h3>
              <Ingredients products={products} />

              <Can
                perform="material:view"
                yes={() => (
                  <>
                    <h3>Raw Materials</h3>
                    <Materials products={products} />
                  </>
                )}
              />

              <Can
                perform="formula:add"
                yes={() => (
                  <>
                    <Fab
                      component={Link}
                      color="primary"
                      className={classes.fabButton}
                      to={`/update/formula/${products[0].id}/`}
                      aria-label="Add"
                    >
                      <AddIcon />
                    </Fab>
                  </>
                )}
              />
            </Paper>
          </>
        );
      }}
    </Query>
  );
}

ProductDetailsPage.defaultProps = {};

ProductDetailsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductDetailsPage);
