import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import Box from '@material-ui/core/Box';

/*
#A5D8DA
#27827B',
#66BFB6',
*/

const useStyles = makeStyles(theme => ({
  footer: {
    // position: 'fixed',
    // bottom: 0,
    // left: 0,
    // width: '100%',
    backgroundColor: '#fff',
    borderTop: '2px solid #66BFB6',
    marginTop: 'auto',
  },
  root: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  contact: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 0 0',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
    '& a': {
      padding: '3px 0',
    },
  },
  iconWithText: {
    display: 'flex',
    verticalAlign: 'middle',
    margin: '5px 0',
  },
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography align="left">
              <span>© 2020: FRAMES FORMULATION INTELLIGENCE B.V.</span>
              <br />
              <span>PLATFORM FOR HOME & PERSONAL CARE PRODUCTS</span>
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <span className={classes.contact}>
                  <a
                    className={classes.iconWithText}
                    href="tel:+31 683 707 022"
                  >
                    <PhoneIcon color="primary">add_circle</PhoneIcon>&nbsp;+31
                    683 707 022
                  </a>
                  <a
                    className={classes.iconWithText}
                    href="tel:+31 629 093 644"
                  >
                    <PhoneIcon color="primary">add_circle</PhoneIcon>&nbsp;+31
                    629 093 644
                  </a>
                </span>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <span className={classes.contact}>
                  <a
                    className={classes.iconWithText}
                    href="mailto:info@frames.consulting"
                  >
                    <MailIcon color="primary" />
                    &nbsp;info@frames.consulting
                  </a>
                  <a
                    className={classes.iconWithText}
                    href="https://frames.consulting/"
                  >
                    <LanguageIcon color="primary" />
                    &nbsp;frames.consulting
                  </a>
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box mb={2}>
              <h3 style={{ marginTop: 0 }}>
                Request a specific product/ingredient:
              </h3>
            </Box>
            <Button
              variant="contained"
              type="button"
              color="primary"
              className={classes.button}
              href="mailto:info@frames.consulting"
              aria-label="Load more"
            >
              Contact us
            </Button>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
