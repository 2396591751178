import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getFormulas } from '../../scripts/utils';
import Price, { formatPrice } from '../Price';
import Percentage from '../Percentage';
import Can from '../Can';
import ExportXLS from '../../components/ExportXLS';

import layout from '../../config/product-layout';
import { gql, useMutation } from '@apollo/client';
import { TextField } from '@material-ui/core';
import CopyFormulaDialog from './CopyFormulaDialog';

const UPDATE_FORMULA = gql`
  mutation updateFormula($id: Int!, $setInput: formula_set_input) {
    formula: update_formula(where: { id: { _eq: $id } }, _set: $setInput) {
      affected_rows
      formula: returning {
        id
        percentage_min_sds
        percentage_max_sds
        percentage_min_label
        percentage_max_label
        percentage_frames
      }
    }
  }
`;

const display = layout.product;

const styles = () => ({
  headCell: {},
  input: {
    fontSize: '14px',
    padding: '0!important',
    minHeight: '14px',
  },
});

const StyledPrice = withStyles(() => ({
  textAlign: 'right',
}))(Price);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(165, 216, 218, 0.15)',
    },
  },
}))(TableRow);

function ProductIngredients(props) {
  const { classes, products } = props;
  const [errors, setErrors] = useState({});
  const [id, setId] = useState(null);
  const formulas = getFormulas(products);
  const [updateFormula] = useMutation(UPDATE_FORMULA, {
    onError: err => {
      setErrors({ ...errors, [id]: err });
    },
  });

  const handleCellEditCommit = ({ id, field, value }) => {
    const val = value === '' ? null : value;
    setId(id);
    const newErrors = { ...errors };
    delete newErrors[id];
    setErrors(newErrors);

    updateFormula({
      variables: {
        id: id,
        setInput: {
          [field]: val,
        },
      },
    });
  };

  return (
    <>
      <TableRow>
        <TableCell
          xcolSpan={products.length + 1}
          className={classes.headerCell}
        >
          <h3>Ingredients</h3>
          <ExportXLS data={formulas} fields={display.fields} button="Export" />
        </TableCell>
        {products.map(product => {
          return (
            <TableCell className={classes.valueCell} key={`copy-${product.id}`}>
              <Can
                perform="formula:edit"
                yes={() => <CopyFormulaDialog id={product.id} />}
                no={() => null}
              />
            </TableCell>
          );
        })}
      </TableRow>

      {formulas.map(formula => (
        <StyledTableRow key={formula.name}>
          <TableCell className={classes.headCell} component="th" scope="row">
            {formula.name}
          </TableCell>
          {products.map(product => {
            const ingredient = product.formulas.find(
              f => f.ingredient.name === formula.name,
            );
            if (!ingredient)
              return (
                <TableCell
                  className={classes.valueCell}
                  key={`${formula.name}-${product.id}`}
                >
                  -
                </TableCell>
              );
            if (
              ingredient &&
              !ingredient.ranking &&
              !ingredient.percentage_frames &&
              !ingredient.ingredient.price_active
            )
              return (
                <TableCell
                  className={classes.valueCell}
                  key={`${formula.name}-${product.id}`}
                >
                  -
                </TableCell>
              );

            return (
              <TableCell
                className={classes.valueCell}
                key={`${formula.name}-${product.id}`}
              >
                {ingredient && ingredient.ranking ? (
                  <Can
                    perform="product.field.ranking"
                    yes={() => ingredient.ranking}
                    no={() => '-'}
                  />
                ) : (
                  '-'
                )}

                {ingredient ? (
                  <Can
                    perform="product.field.percentage_frames"
                    yes={() => (
                      <>
                        {' / '}
                        <Can
                          perform="formula:edit"
                          yes={() => (
                            <>
                              <TextField
                                defaultValue={ingredient.percentage_frames}
                                margin="dense"
                                size="small"
                                variant="outlined"
                                error={errors[ingredient.id]}
                                style={{
                                  width: '45px',
                                  margin: 0,
                                  padding: '0 4px',
                                }}
                                onBlur={e => {
                                  handleCellEditCommit({
                                    id: ingredient.id,
                                    field: 'percentage_frames',
                                    value: e.target.value,
                                  });
                                }}
                                inputProps={{
                                  style: {
                                    fontSize: '14px',
                                    padding: '2px 4px',
                                    minHeight: '14px',
                                  },
                                }}
                              ></TextField>
                              %
                            </>
                          )}
                          no={() => {
                            if (ingredient.percentage_frames) {
                              return (
                                <>
                                  <Percentage
                                    value={ingredient.percentage_frames}
                                  />
                                  %
                                </>
                              );
                            }
                            return ' -';
                          }}
                        />
                      </>
                    )}
                    no={() => ' / -'}
                  />
                ) : (
                  ' / -'
                )}

                {ingredient &&
                ingredient.ingredient.price_active &&
                ingredient.percentage_frames ? (
                  <Can
                    perform="product.field.price_active"
                    yes={() => (
                      <>
                        {' / '}
                        <StyledPrice
                          value={
                            (ingredient.ingredient.price_active *
                              ingredient.percentage_frames) /
                            100
                          }
                          decimals={0}
                        />
                      </>
                    )}
                    no={() => ' / -'}
                  />
                ) : (
                  ' / -'
                )}
              </TableCell>
            );
          })}
        </StyledTableRow>
      ))}
    </>
  );
}

ProductIngredients.defaultProps = {};

ProductIngredients.propTypes = {
  classes: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
};

export default withStyles(styles)(ProductIngredients);
