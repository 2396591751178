/**
 *
 * ProductsPage
 *
 */

import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components/macro';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import InfoIcon from '@material-ui/icons/Info';

import { debounce } from 'lodash';
import { connect } from 'react-redux';
import LoadingIndicator from '../../components/LoadingIndicator';
import Error from '../../components/Error';

import { GET_MATERIALS } from '../../queries';
import Concat from '../../components/Concat';

import { getFilter } from '../../scripts/utils';
import { getSearch, getValues } from '../../store/selectors';

import layout from '../../config/material-layout.json';

const display = layout.material;

// Utils
const MAXITEMS = 24;
const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingBottom: 100,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    height: '100%',
    border: `1px solid #A5D8DA`,
    boxShadow: 'none',
  },
  actions: {
    alignItems: 'flex-end',
    margin: 'auto 0 0 0',
  },
  buttons: {
    margin: '20px -5px',
  },
  button: {
    margin: 5,
  },
});

const StyledConcat = styled(Concat)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Listing = props => {
  const { classes, searchValue, values, limit } = props;
  const [filter, setFilter] = useState();

  useEffect(() => {
    const handleFilter = debounce(() => {
      const f = getFilter('material', searchValue, values, display.fields);
      setFilter(f);
    }, 500);
    handleFilter();
  }, [searchValue, values]);

  // const [offset, setOffset] = useState(propsOffset);
  const { loading, error, data, fetchMore } = useQuery(GET_MATERIALS, {
    variables: { offset: 0, limit, filter },
  });

  const onLoadMore = offset => {
    const variables = {
      limit,
      offset,
    };
    if (fetchMore === undefined) return;
    fetchMore({
      // query,
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }
        const materials =
          variables.offset === 0
            ? fetchMoreResult.materials
            : previousResult.materials.concat(fetchMoreResult.materials);

        return {
          allItems: fetchMoreResult.allItems,
          materials,
        };
      },
    });
  };

  const handleMore = o => {
    onLoadMore(o);
  };

  if (loading) return <LoadingIndicator />;
  if (error) return <Error message={error.message} />;

  const { materials: items, allItems } = data;
  const count = items ? items.length : 0;
  const max = allItems ? allItems.aggregate.count : 0;

  return (
    <>
      <h4>
        {max > 0 ? `Show ${count} of ${max} materials` : 'No materials found'}
      </h4>
      <Grid container spacing={2}>
        {items &&
          items.length !== 0 &&
          items.map(item => (
            <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
              <Card className={classes.card} elevation={2}>
                <CardContent>
                  <StyledConcat first={item.ingredient.name} empty="-" />
                  <StyledConcat first={item.trade_name} empty="-" />
                  <StyledConcat first={item.supplier.name} empty="-" />
                  <StyledConcat first={item.description} empty="-" />
                  <StyledConcat first={item.format} empty="-" />
                </CardContent>
                <CardActions className={classes.actions}>
                  <Link to={`/material/${item.id}`}>
                    <IconButton aria-label="Details" color="primary">
                      <InfoIcon />
                    </IconButton>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      <div className={classes.buttons}>
        {count < max && (
          <Button
            variant="contained"
            type="button"
            color="primary"
            className={classes.button}
            onClick={() => handleMore(count)}
            aria-label="Load more"
          >
            Load more
          </Button>
        )}
      </div>
    </>
  );
};

Listing.defaultProps = {
  limit: MAXITEMS,
  searchValue: '',
};

Listing.propTypes = {
  classes: PropTypes.object.isRequired,
  limit: PropTypes.number,
  searchValue: PropTypes.string,
  values: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {
    searchValue: getSearch(state, 'materials'),
    values: getValues(state, 'materials'),
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(Listing));
