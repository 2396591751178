import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { GET_FORMULA_SYNC } from '../../queries';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Auth0Context } from '../../hooks/AuthContext';
import { Box, IconButton, Typography } from '@material-ui/core';
import LoadingIndicator from '../LoadingIndicator';

const UPDATE_FORMULA = gql`
  mutation updateFormula($id: Int!, $setInput: formula_set_input) {
    formula: update_formula(where: { id: { _eq: $id } }, _set: $setInput) {
      affected_rows
      formula: returning {
        id
        percentage_min_sds
        percentage_max_sds
        percentage_min_label
        percentage_max_label
        percentage_frames
      }
    }
  }
`;

export default function CopyFormulaDialog(props) {
  const { id: destinationProductId } = props;
  const [open, setOpen] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [fetched, setFetched] = React.useState(false);
  const [synced, setSynced] = React.useState(false);
  const [sourceProductId, setSourceProductId] = React.useState('');
  const [formulas, setFormulas] = React.useState([]);
  const [errors, setErrors] = useState([]);

  const authContext = useContext(Auth0Context);
  const { user } = authContext;

  const [updateFormula] = useMutation(UPDATE_FORMULA, {
    onError: error => {
      setErrors([...errors, error.message]);
    },
  });

  const { loading, error, data } = useQuery(GET_FORMULA_SYNC, {
    variables: {
      destination_product_id: destinationProductId,
      source_product_id: sourceProductId,
    },
    skip: !open || !fetching || !destinationProductId || !sourceProductId,
  });

  useEffect(() => {
    if (!loading && !error && data) {
      setFormulas(
        data.formula
          .filter(formula => {
            return formula.ingredient.formulas.length > 0;
          })
          .map(formula => {
            const sourceFormula = formula.ingredient.formulas[0];
            return {
              destination: {
                ...formula,
              },
              source: {
                ...sourceFormula,
              },
            };
          }),
      );
      setFetched(true);
      setFetching(false);
    }
  }, [data, fetching]);

  const handleSync = () => {
    formulas.forEach(formula => {
      updateFormula({
        variables: {
          id: formula.destination.id,
          setInput: {
            percentage_frames: formula.source.percentage_frames,
          },
        },
      });
    });
    setFetched(false);
    setSynced(true);
    setFormulas([]);
  };

  const handleFetch = () => {
    setFetching(true);
    setErrors([]);
    setSynced(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSourceProductId('');
    setFetched(false);
    setFormulas([]);
    setFetching(false);
    setSynced(false);
    setErrors([]);
  };

  const handleChange = e => {
    setSourceProductId(e.target.value);
    setFetched(false);
    setFormulas([]);
    setSynced(false);
    setErrors([]);
  };

  return (
    <div>
      <IconButton aria-label="Synchronize" color="primary" onClick={handleOpen}>
        <SyncAltIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle id="form-dialog-title">Synchronize</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Synchronize "Frames percentage" of product ${destinationProductId} with product:`}
          </DialogContentText>
          <Box style={{ display: 'flex', marginBottom: '20px' }}>
            <TextField
              autoFocus
              margin="dense"
              id="source_product_id"
              label="Product"
              type="numeric"
              onChange={handleChange}
              size="small"
              variant="outlined"
              value={sourceProductId}
            />
            &nbsp;
            <Button
              onClick={handleFetch}
              color="primary"
              variant="contained"
              size="small"
              style={{ margin: '8px' }}
            >
              Read product
            </Button>
          </Box>
          {loading && <LoadingIndicator />}
          {sourceProductId && fetched && (
            <Typography style={{ fontWeight: 'bold' }} gutterBottom>
              {formulas.length === 0
                ? 'No matching ingredients'
                : 'Matching ingredients:'}
            </Typography>
          )}
          {sourceProductId && synced && (
            <Typography style={{ fontWeight: 'bold' }} gutterBottom>
              {errors.length === 0
                ? 'All matching ingredients synced'
                : 'There are some errors:'}
            </Typography>
          )}
          {formulas.map(formula => {
            return (
              <Typography key={formula.destination.ingredient.id}>
                {`${formula.destination.ingredient.name} ${formula.destination.percentage_frames} change to ${formula.source.percentage_frames} `}
              </Typography>
            );
          })}
          {errors.map((error, i) => {
            return (
              <Typography color="error" key={i}>
                {error}
              </Typography>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {!synced && (
            <Button
              onClick={handleSync}
              color="primary"
              disabled={formulas.length === 0}
              variant="contained"
            >
              Synchronize
            </Button>
          )}
          {synced && (
            <Button onClick={handleClose} color="primary" variant="contained">
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
