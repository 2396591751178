/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import LoadingIndicator from '../../components/LoadingIndicator';

import Error from '../../components/Error';
import { normalizeString } from '../../scripts/utils';

import productLayout from '../../config/product-layout';
import formulaLayout from '../../config/formula-layout';

import {
  BULK_INSERT_SUPPLIERS,
  BULK_INSERT_INGREDIENT_SYNONYMS,
  BULK_INSERT_PRODUCTS,
  SELECT_INGREDIENT_SYNONYMS,
  BULK_INSERT_INGREDIENTS,
} from '../../queries';

const Papa = require('papaparse/papaparse.min.js');

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: '0 0 30px 0',
    flex: '0 0 100%',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
  },
  fieldset: {
    width: '100%',
  },
  formHelperText: {
    fontSize: '1em',
    color: '#333',
    lineHeight: '1.4',
  },
  button: {
    margin: '5px',
  },
  buttons: {
    margin: -5,
  },
  error: {
    color: 'red',
    margin: '10px 0',
    fontSize: 16,
  },
  textarea: {
    fontSize: '18px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
});

const tableNames = [
  {
    value: 'ingredient_synonym',
    label: 'Ingredient Synonyms',
  },

  {
    value: 'supplier',
    label: 'Suppliers',
  },
  {
    value: 'product',
    label: 'Products',
  },
  {
    value: 'ingredient',
    label: 'Ingredient',
  },
];

const HelpText = ({ tableName }) => {
  let txt = 'Copy paste from Excel';
  switch (tableName) {
    case 'product':
      txt =
        'There should be at least two columns: first column is the header column: brand_name, variant_name, brand_owner, country and website. You can extend this column with number corresponding the ranking of the ingredient. Second column are the values of the product.';
      break;
    case 'supplier':
      txt =
        'There should be one column. One headercolumns in the first row: name';
      break;
    case 'ingredient_synonym':
      txt =
        'There should be two columns. Two headercolumns in the first row: name and ingredient. name is your name of the synonym and ingredient is the name of the ingredient in the database.';
      break;
    case 'ingredient':
      txt =
        'There should be six columns: first column is the header column: id, name, price_active, cas, type, class_name';
      break;
    default:
      txt = 'Copy paste from Excel';
  }

  return <span>{txt}</span>;
};

const Form = props => {
  const { classes } = props;
  const [parseError, setParseError] = useState(undefined);
  const [
    addSuppliers,
    { loading: loadingSuppliers, error: errorSuppliers, data: dataSuppliers },
  ] = useMutation(BULK_INSERT_SUPPLIERS);
  const [
    addIngredientSynonyms,
    {
      loading: loadingIngredientSynonyms,
      error: errorIngredientSynonyms,
      data: dataIngredientsSynonyms,
    },
  ] = useMutation(BULK_INSERT_INGREDIENT_SYNONYMS);

  const [
    addProducts,
    { loading: loadingProducts, error: errorProducts, data: dataProducts },
  ] = useMutation(BULK_INSERT_PRODUCTS);

  const {
    loading: loadingSelectIngredients,
    error: errorSelectIngredients,
    data: dataSelectIngredients,
  } = useQuery(SELECT_INGREDIENT_SYNONYMS);

  const [
    addIngredients,
    {
      loading: loadingIngredients,
      error: errorIngredients,
      data: dataIngredients,
    },
  ] = useMutation(BULK_INSERT_INGREDIENTS);

  const isNumber = value => !Number.isNaN(parseFloat(value));
  const transpose = a => {
    // Calculate the width and height of the Array
    const w = a.length || 0;
    const h = a[0] instanceof Array ? a[0].length : 0;

    // In case it is a zero matrix, no transpose routine needed.
    if (h === 0 || w === 0) {
      return [];
    }

    /**
     * @var {Number} i Counter
     * @var {Number} j Counter
     * @var {Array} t Transposed data is stored in this array.
     */
    let i;
    let j;
    const t = [];

    // Loop through every item in the outer array (height)
    for (i = 0; i < h; i += 1) {
      // Insert a new row (array)
      t[i] = [];

      // Loop through every item per item in outer array (width)
      for (j = 0; j < w; j += 1) {
        // Save transposed data.
        t[i][j] = a[j][i];
      }
    }

    return t;
  };

  // You can now query the GraphQL API
  /*
const response = await client.query({
  query: gql`
    query {
      people(query: "age > 18", sortBy: "name") {
        name
        age
      }
    }
  `
});
*/
  const importProductData = data => {
    Papa.parse(data, {
      header: false,
      skipEmptyLines: true,
      delimitersToGuess: ['\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
      transform: value => {
        const v = value.trim();
        return v;
      },
      complete(results) {
        const transposed = transpose(results.data);
        const headers = transposed.shift();

        const errors = [];
        const result = transposed.map(row =>
          row.reduce((acc, col, ind) => {
            let val = col;
            const name = headers[ind];
            try {
              if (name.substring(0, 7) === 'formula') {
                const [fields, userFields] = name.split(' ');
                const [relation, colname, id] = fields.split(':');
                if (val === undefined || val === '') {
                  return acc;
                }
                console.log('try', relation, colname, id, val);
                if (
                  relation !== 'formula' ||
                  id === undefined ||
                  colname === undefined
                ) {
                  errors.push(`FORMULA ${userFields} is wrong format`);
                  return acc;
                }

                const { type, options } = formulaLayout.formula.fields[colname];

                if (type === 'float') {
                  val = parseFloat(val);
                } else if (type === 'integer') {
                  val = parseInt(val, 10);
                } else if (type === 'select' && options && options.length > 0) {
                  const found = options.find(option => option === val);
                  if (found === undefined) {
                    errors.push(`${colname} does not accept ${col}`);
                  }
                } else {
                  val = col;
                }

                if (!acc.formulas) {
                  acc.formulas = {};
                  acc.formulas.data = [];
                }
                if (!Array.isArray(acc.formulas.data)) {
                  acc.formulas.data = [];
                }

                const index = acc.formulas.data.findIndex(
                  formula => formula.ingredient_id === id,
                );

                if (index === -1) {
                  acc.formulas.data.push({
                    ingredient_id: id,
                    [colname]: val,
                  });
                } else {
                  acc.formulas.data[index] = {
                    ...acc.formulas.data[index],
                    [colname]: val,
                  };
                }
              } else if (isNumber(name)) {
                let id = null;
                const ingredientName = normalizeString(col);
                if (ingredientName === undefined || ingredientName === '') {
                  return acc;
                }

                const ingredient =
                  dataSelectIngredients &&
                  dataSelectIngredients.ingredients.find(i => {
                    return normalizeString(i.name) === ingredientName;
                  });
                if (ingredient === undefined) {
                  errors.push(`${col}`);
                } else {
                  id = ingredient.ingredient_id;
                }

                if (!acc.formulas) {
                  acc.formulas = {};
                  acc.formulas.data = [];
                }
                if (!Array.isArray(acc.formulas.data)) {
                  acc.formulas.data = [];
                }
                acc.formulas.data.push({
                  ranking: name,
                  ingredient_id: id,
                });
              } else {
                const { type, options } = productLayout.product.fields[name];
                if (val === undefined || val === '') {
                  return acc;
                }
                if (type === 'float') {
                  val = parseFloat(val);
                } else if (type === 'integer') {
                  val = parseInt(val, 10);
                } else if (type === 'select' && options && options.length > 0) {
                  const found = options.find(option => option === val);
                  if (found === undefined) {
                    errors.push(`${name} does not accept ${col}`);
                  }
                } else {
                  val = col;
                }
                acc[name] = val;
              }
            } catch (e) {
              errors.push(`Field ${name} is not in database`);
            }
            return acc;
          }, {}),
        );

        const error = errors.map(err => err).join('\n');

        if (error) {
          setParseError(error);
        } else {
          addProducts({ variables: { products: result } });
        }
      },
      error: error => {
        setParseError(error);
      },
    });
  };

  const importIngredientSynonymData = data => {
    const errors = [];
    Papa.parse(data, {
      header: true,
      skipEmptyLines: true,
      delimitersToGuess: ['\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
      transform: (value, column) => {
        let v = value;
        if (column === 'ingredient') {
          const strippedName = normalizeString(value);
          const ingredient =
            dataSelectIngredients &&
            dataSelectIngredients.ingredients.find(i => {
              return normalizeString(i.name) === strippedName;
            });
          if (ingredient === undefined) {
            errors.push(`${value}`);
          } else {
            v = ingredient.ingredient_id;
          }
        }
        return v;
      },
      complete(results) {
        const error = errors.map(err => err).join('\n');
        if (error) {
          setParseError(error);
        } else {
          const { data } = results;
          const result = data.map(row => {
            const newRow = { ...row };
            newRow.stripped_name = normalizeString(row.name);
            newRow.ingredient_id = newRow.ingredient;
            delete newRow.ingredient;
            return newRow;
          });
          addIngredientSynonyms({ variables: { synonyms: result } });
        }
      },
    });
  };

  const importSupplierData = data => {
    Papa.parse(data, {
      header: true,
      skipEmptyLines: true,
      delimitersToGuess: ['\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
      transform: value => {
        const v = value.trim();
        return v;
      },
      complete(results) {
        const { data } = results;
        addSuppliers({ variables: { suppliers: data } });
      },
    });
  };

  const importIngredientData = data => {
    Papa.parse(data, {
      header: true,
      skipEmptyLines: true,
      delimitersToGuess: ['\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
      transform: (value, column) => {
        let v = value.trim();
        if (column === 'price_active') {
          if (v.toUpperCase() === 'NULL') {
            v = undefined;
          }
        }
        return v;
      },
      complete(results) {
        const { data } = results;
        addIngredients({ variables: { ingredients: data } });
      },
    });
  };

  let errors = [];
  if (errorIngredientSynonyms) {
    errors = [...errors, ...errorIngredientSynonyms.graphQLErrors];
  }
  if (errorSuppliers) {
    errors = [...errors, ...errorSuppliers.graphQLErrors];
  }
  if (errorSelectIngredients) {
    errors = [...errors, ...errorSelectIngredients.graphQLErrors];
  }
  if (errorProducts) {
    errors = [...errors, ...errorProducts.graphQLErrors];
  }
  if (errorIngredients) {
    errors = [...errors, ...errorIngredients.graphQLErrors];
  }

  let error = errors.map(err => err.message).join('\n');

  if (parseError) {
    error = `${error}\n${parseError}`;
  }

  if (error) {
    return (
      <>
        <h3>Something went wrong importing your data</h3>
        <Error message={`${error}`} />
      </>
    );
  }
  if (
    loadingIngredientSynonyms ||
    loadingSuppliers ||
    loadingSelectIngredients ||
    loadingProducts ||
    loadingIngredients
  )
    return <LoadingIndicator />;

  if (dataSuppliers) {
    return (
      <h3>
        You have imported or updated {dataSuppliers.supplier.affected_rows}{' '}
        suppliers
      </h3>
    );
  }
  if (dataIngredientsSynonyms) {
    return (
      <h3>
        You have imported or updated{' '}
        {dataIngredientsSynonyms.ingredient_synonym.affected_rows} synonyms
      </h3>
    );
  }
  if (dataProducts) {
    return (
      <h3>
        You have imported or updated {dataProducts.product.affected_rows}{' '}
        products
      </h3>
    );
  }
  if (dataIngredients) {
    return (
      <h3>
        You have imported or updated {dataIngredients.ingredient.affected_rows}{' '}
        ingredients
      </h3>
    );
  }

  return (
    <Formik
      initialValues={{
        tableName: '',
        importCSV: ``,
      }}
      validationSchema={Yup.object().shape({
        tableName: Yup.string().required('Select your data category'),
        importCSV: Yup.string()
          .min(8, 'importCSV must contain at least 8 characters')
          .required('Enter your importCSV'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          // submit to the server
          // alert(JSON.stringify(values, null, 2));
          switch (values.tableName) {
            case 'product':
              importProductData(values.importCSV);
              break;
            case 'supplier':
              importSupplierData(values.importCSV);
              break;
            case 'ingredient_synonym':
              importIngredientSynonymData(values.importCSV);
              break;
            case 'ingredient':
              importIngredientData(values.importCSV);
              break;
            default:
              setParseError(`You cannot import ${values.tableName}`);
          }
          setSubmitting(false);
        }, 1000);
      }}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;

        return (
          <Paper className={classes.container} elevation={4}>
            <form onSubmit={handleSubmit}>
              <FormControl className={classes.formControl}>
                <TextField
                  select
                  id="tableName"
                  label="Table name"
                  value={values.tableName}
                  onChange={handleChange('tableName')}
                  helperText={touched.tableName ? errors.tableName : ''}
                  error={touched.tableName && Boolean(errors.tableName)}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                >
                  {tableNames.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl className={classes.formControl}>
                <FormLabel component="legend">Paste here your data</FormLabel>

                <TextareaAutosize
                  id="importCSV"
                  label="importCSV"
                  value={values.importCSV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // error={touched.importCSV && Boolean(errors.importCSV)}
                  margin="dense"
                  variant="outlined"
                  rows="20"
                  className={classes.textarea}
                />
                <FormHelperText className={classes.formHelperText}>
                  <HelpText tableName={values.tableName} />
                </FormHelperText>
              </FormControl>
              <div className={classes.buttons}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  disabled={isSubmitting}
                >
                  importCSV
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </div>
            </form>
          </Paper>
        );
      }}
    </Formik>
  );
};

Form.defaultProps = {};

Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Form);
