import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import { FieldArray, Field, getIn } from 'formik';
import { withStyles, styled } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GraphSelect from '../Selects/GraphSelect';
import MySelect from '../Selects/MySelect';
import Files from '../Files/dialog';
import { FILES_URL } from '../../config/constants';

import {
  SELECT_SUPPLIERS,
  SELECT_INGREDIENTS,
  SELECT_FILES,
} from '../../queries';
import GraphCreate from '../Selects/GraphCreate';

const isError = ({ touched, errors, name, submitCount }) => {
  let error = getIn(errors, name);
  const touch = getIn(touched, name); // getIn(form.touched, name);
  if (error && error.name) {
    error = error.name;
  }
  if (error && error.label) {
    error = error.label;
  }
  return (touch || submitCount > 0) && error && typeof error === 'string'
    ? error
    : null;
};

const ErrorMessage = ({ touched, errors, name }) => (
  <Field
    name={name}
    render={({ form }) =>
      isError({ touched, errors, name, submitCount: form.submitCount })
    }
  />
);

ErrorMessage.propTypes = {
  errors: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  touched: PropTypes.object.isRequired,
};

const styles = theme => ({
  cardGrid: {
    padding: `${theme.spacing(2)}px`,
    margin: `-${theme.spacing(2)}px`,
    overflowX: 'hidden',
  },
  legend: {
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
    marginBottom: '5px',
  },
  inputWrapper: {},
  formControl: {
    margin: 0,
    flex: '0 0 100%',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
  },
  fieldset: {
    width: '100%',
  },
  subform: {
    marginTop: theme.spacing(1),
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  imageWrapper: {
    height: '150px',
    width: '250px',
    position: 'relative',
    padding: '20px 0 20px',
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: '0 auto',
  },
});

const DeleteButton = styled(IconButton)({
  position: 'absolute',
  top: '10px',
  right: '10px',
  zIndex: '3',
  color: 'red',
});

const FieldList = props => {
  const {
    classes,
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    display,
    baseName,
    submitCount,
  } = props;

  return (
    <div className={classes.cardGrid}>
      <Grid container spacing={2}>
        {Object.keys(display.fields).map(name => {
          const input = display.fields[name];
          const { label } = input;
          let { type } = input;
          let width = {
            xs: 12,
            sm: 6,
            md: 6,
          };
          if (input.width) {
            width = { ...width, ...input.width };
          }
          let inputElement;
          switch (input.type) {
            case 'decimal':
              type = 'number';
              break;
            case 'float':
              type = 'number';
              break;
            case 'integer':
              type = 'number';
              break;
            case 'string':
              type = 'text';
              break;
            default:
          }

          switch (input.type) {
            case 'subform':
              inputElement = null;
              break;
            case 'aggregate':
              inputElement = null;
              break;
            case 'filter':
              inputElement = null;
              break;
            case 'subformd':
              inputElement = (
                <FormControl
                  id={`${baseName}${name}`}
                  className={classes.fieldset}
                  error={!!isError({ touched, errors, name, submitCount })}
                  aria-describedby={`${display.name}-${name}-helper-text`}
                >
                  <FormLabel component="legend" className={classes.formControl}>
                    {label}
                  </FormLabel>
                  <div className={classes.subform}>
                    <FieldArray
                      name={name}
                      render={arrayHelpers => (
                        <>
                          {values[name] && values[name].length > 0 ? (
                            values[name].map((subformValue, j) => {
                              const fieldArrayErrors =
                                errors[name] && errors[name][j]
                                  ? errors[name][j]
                                  : [];
                              const fieldArrayTouched =
                                touched[name] && touched[name][j]
                                  ? touched[name][j]
                                  : [];
                              return (
                                <React.Fragment
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`${display.name}.${name}.${j}`}
                                >
                                  <FieldList
                                    {...props}
                                    display={input}
                                    values={subformValue || {}}
                                    // values={values}
                                    // errors={errors}
                                    id={`${display.name}.${name}.${j}`}
                                    errors={fieldArrayErrors || []}
                                    touched={fieldArrayTouched || []}
                                    baseName={`${baseName}${name}.${j}.`}
                                  />
                                  <Button
                                    variant="contained"
                                    type="button"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => arrayHelpers.remove(j)} // remove a friend from the list
                                  >
                                    -
                                  </Button>
                                </React.Fragment>
                              );
                            })
                          ) : (
                            <div key={`${display.name}.${name}.0`} />
                          )}
                          <Button
                            variant="contained"
                            type="button"
                            color="primary"
                            className={classes.button}
                            onClick={() => arrayHelpers.push(input.empty || {})}
                          >
                            +
                          </Button>
                        </>
                      )}
                    />
                  </div>

                  <FormHelperText id={`${display.name}-${name}-helper-text`}>
                    <ErrorMessage
                      touched={touched}
                      errors={errors}
                      name={name}
                    />
                  </FormHelperText>
                </FormControl>
              );
              break;
            case 'filteredSelect':
            case 'select':
              inputElement = (
                <FormControl
                  id={`${baseName}${name}`}
                  className={classes.formControl}
                  error={!!isError({ touched, errors, name, submitCount })}
                  aria-describedby={`${display.name}-${name}-helper-text`}
                >
                  <FormLabel component="legend" className={classes.legend}>
                    {label}
                  </FormLabel>
                  <MySelect
                    id={`${baseName}${name}`}
                    value={{
                      value: values[name] || '',
                      label: values[name] || '',
                    }}
                    label={name}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={!!isError({ touched, errors, name, submitCount })}
                    touched={touched.topics}
                    options={input.options.map(option => ({
                      value: option,
                      label: option,
                    }))}
                  />
                  <FormHelperText id={`${display.name}-${name}-helper-text`}>
                    <ErrorMessage
                      touched={touched}
                      errors={errors}
                      name={name}
                    />
                  </FormHelperText>
                </FormControl>
              );
              break;
            case 'ingredients':
              inputElement = (
                <FormControl
                  id={`${baseName}${name}`}
                  className={classes.formControl}
                  error={!!isError({ touched, errors, name, submitCount })}
                  aria-describedby={`${display.name}-${name}-helper-text`}
                >
                  <FormLabel component="legend" className={classes.legend}>
                    Ingredient
                  </FormLabel>
                  <GraphSelect
                    id={`${baseName}${name}`}
                    onChange={value => {
                      setFieldValue(`${baseName}${name}`, value);
                      handleChange();
                    }}
                    value={values[name] || null}
                    onBlur={setFieldTouched}
                    error={!!isError({ touched, errors, name, submitCount })}
                    touched={touched[name]}
                    query={SELECT_INGREDIENTS}
                  />{' '}
                  <FormHelperText id={`${display.name}-${name}-helper-text`}>
                    <ErrorMessage
                      touched={touched}
                      errors={errors}
                      name={name}
                    />
                  </FormHelperText>
                </FormControl>
              );
              break;
            case 'suppliers':
              inputElement = (
                <FormControl
                  id={`${baseName}${name}`}
                  className={classes.formControl}
                  error={!!isError({ touched, errors, name, submitCount })}
                  aria-describedby={`${display.name}-${name}-helper-text`}
                >
                  <FormLabel component="legend" className={classes.legend}>
                    Supplier
                  </FormLabel>
                  <GraphSelect
                    id={`${baseName}${name}`}
                    onChange={value => {
                      setFieldValue(`${baseName}${name}`, value);
                      handleChange();
                    }}
                    value={values[name] || null}
                    onBlur={setFieldTouched}
                    error={!!isError({ touched, errors, name, submitCount })}
                    touched={touched[name]}
                    query={SELECT_SUPPLIERS}
                  />
                </FormControl>
              );
              break;

            case 'files':
              inputElement = (
                <>
                  {values[name] && (
                    <div className={classes.imageWrapper}>
                      <img
                        className={classes.image}
                        src={`${FILES_URL}${values[name].path}`}
                        alt={values[name].name}
                      />
                      <DeleteButton
                        aria-label="Delete"
                        color="inherit"
                        onClick={() => {
                          setFieldValue(`${baseName}${name}`, null);
                          handleChange();
                        }}
                      >
                        <HighlightOffIcon />
                      </DeleteButton>
                    </div>
                  )}
                  <Files
                    value={values[name] || null}
                    onChange={value => {
                      setFieldValue(`${baseName}${name}`, value);
                      handleChange();
                    }}
                  />
                </>
              );
              break;
            case 'filesxx':
              inputElement = (
                <FormControl
                  id={`${baseName}${name}`}
                  className={classes.formControl}
                  error={!!isError({ touched, errors, name, submitCount })}
                  aria-describedby={`${display.name}-${name}-helper-text`}
                >
                  <FormLabel component="legend" className={classes.legend}>
                    File
                  </FormLabel>
                  <GraphSelect
                    id={`${baseName}${name}`}
                    onChange={value => {
                      setFieldValue(`${baseName}${name}`, value);
                      handleChange();
                    }}
                    value={values[name] || null}
                    onBlur={setFieldTouched}
                    error={!!isError({ touched, errors, name, submitCount })}
                    touched={touched[name]}
                    query={SELECT_FILES}
                  />
                </FormControl>
              );
              break;
            case 'date': {
              inputElement = (
                <FormControl
                  id={`${baseName}${name}`}
                  className={classes.formControl}
                  error={!!isError({ touched, errors, name, submitCount })}
                  aria-describedby={`${display.name}-${name}-helper-text`}
                >
                  <DatePicker
                    id={`${baseName}${name}`}
                    name={`${baseName}${name}`}
                    format="yyyy-MM-dd"
                    clearable
                    autoOk
                    label={label}
                    value={values[name] || null}
                    onChange={value => {
                      setFieldValue(`${baseName}${name}`, value);
                      handleChange();
                    }}
                  />
                  <FormHelperText id={`${display.name}-${name}-helper-text`}>
                    <ErrorMessage
                      touched={touched}
                      errors={errors}
                      name={name}
                    />
                  </FormHelperText>
                </FormControl>
              );
              break;
            }
            case 'datetime':
              inputElement = (
                <FormControl
                  id={`${baseName}${name}`}
                  className={classes.formControl}
                  error={!!isError({ touched, errors, name, submitCount })}
                  aria-describedby={`${display.name}-${name}-helper-text`}
                >
                  <TextField
                    id={`${baseName}${name}`}
                    label={label}
                    type="date"
                    value={values[name] || null}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormHelperText id={`${display.name}-${name}-helper-text`}>
                    <ErrorMessage
                      touched={touched}
                      errors={errors}
                      name={name}
                    />
                  </FormHelperText>
                </FormControl>
              );

              break;
            case 'computed':
              inputElement = (
                <FormControl
                  id={`${baseName}${name}`}
                  className={classes.formControl}
                  error={!!isError({ touched, errors, name, submitCount })}
                  aria-describedby={`${display.name}-${name}-helper-text`}
                >
                  <InputLabel htmlFor={`${name}`}>{label}</InputLabel>
                  <Input
                    readOnly
                    disabled
                    id={`${baseName}${name}`}
                    type={type}
                    value={
                      values[name] === undefined || values[name] === null
                        ? ''
                        : values[name]
                    }
                  />
                </FormControl>
              );
              break;
            case 'creatable':
              inputElement = (
                <FormControl
                  id={`${baseName}${name}`}
                  className={classes.formControl}
                  error={!!isError({ touched, errors, name, submitCount })}
                  aria-describedby={`${display.name}-${name}-helper-text`}
                >
                  <FormLabel component="legend" className={classes.legend}>
                    {label}
                  </FormLabel>
                  <GraphCreate
                    id={`${baseName}${name}`}
                    onChange={value => {
                      setFieldValue(`${baseName}${name}`, value.label);
                      handleChange();
                    }}
                    value={values[name] || null}
                    onBlur={setFieldTouched}
                    error={!!isError({ touched, errors, name, submitCount })}
                    touched={touched[name]}
                    query={input.options.query}
                    mutate={input.options.mutate}
                  />
                  <FormHelperText id={`${display.name}-${name}-helper-text`}>
                    <ErrorMessage
                      touched={touched}
                      errors={errors}
                      name={name}
                    />
                  </FormHelperText>
                </FormControl>
              );
              break;

            default:
              inputElement = (
                <FormControl
                  id={`${baseName}${name}`}
                  className={classes.formControl}
                  error={!!isError({ touched, errors, name, submitCount })}
                  aria-describedby={`${display.name}-${name}-helper-text`}
                >
                  <InputLabel htmlFor={`${name}`}>{label}</InputLabel>
                  <Input
                    id={`${baseName}${name}`}
                    type={type}
                    value={
                      values[name] === undefined || values[name] === null
                        ? ''
                        : values[name]
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormHelperText id={`${display.name}-${name}-helper-text`}>
                    <ErrorMessage
                      touched={touched}
                      errors={errors}
                      name={name}
                    />
                  </FormHelperText>
                </FormControl>
              );
          }
          return (
            <Grid
              item
              key={`${baseName}${name}`}
              xs={width.xs}
              sm={width.sm}
              className={classes.inputWrapper}
            >
              {inputElement}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

FieldList.defaultProps = {
  touched: {},
  values: [],
  errors: [],
};

FieldList.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  display: PropTypes.object.isRequired,
  baseName: PropTypes.string.isRequired,
  submitCount: PropTypes.number.isRequired,
};

export default withStyles(styles)(FieldList);
