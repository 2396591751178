const rules = {
  visitor: {},

  user: {
    static: [
      'product:list',
      'product:zoom',
      'product:filter',

      'product.field.ranking',
      
      'ingredient:list',
      'ingredient:filter',

      'ingredient.field.name',
      'ingredient.field.class_name',
      'ingredient.field.cas',
      'ingredient.field.ec_number',
    ],
    dynamic: {
      'posts:edit': ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
    },
  },


  basic: {
    static: [
      'product:list',
      'product:compare',
      'product:detail',
      'product:zoom',
      'product:filter',

      'product.field.ranking',

      'ingredient:list',
      'ingredient:compare',
      'ingredient:detail',
      'ingredient:filter',

      'ingredient.field.name',
      'ingredient.field.type',
      'ingredient.field.class_name',
      'ingredient.field.cas',
      'ingredient.field.ec_number',
      'ingredient.field.function',
      'ingredient.field.format',
      'ingredient.field.price_source',
      'ingredient.field.mol_weight',
      'ingredient.field.mol_format',
      'ingredient.field.sustainability_description',
      'ingredient.field.sustainability_rating',
      'ingredient.field.volume',
      'ingredient.field.volume_description',
    ],
    dynamic: {
      'posts:edit': ({ userId, postOwnerId }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
    },
  },
  plus: {
    static: [
      'product:list',
      'product:compare',
      'product:detail',
      'product:zoom',
      'product:filter',

      'product.field.ranking',
      'product.field.percentage',
      'product.field.percentage_label',
      'product.field.percentage_frames',
      'product.field.percentage_sds_ingredients',

      'ingredient:list',
      'ingredient:compare',
      'ingredient:detail',
      'ingredient:filter',
      'ingredient:products',

      'ingredient.field.name',
      'ingredient.field.type',
      'ingredient.field.class_name',
      'ingredient.field.cas',
      'ingredient.field.ec_number',
      'ingredient.field.function',
      'ingredient.field.format',
      'ingredient.field.price_source',
      'ingredient.field.mol_weight',
      'ingredient.field.mol_format',
      'ingredient.field.sustainability_description',
      'ingredient.field.sustainability_rating',
      'ingredient.field.volume',
      'ingredient.field.volume_description',
    ],
  },
  max: {
    static: [
      'product:list',
      'product:compare',
      'product:detail',
      'product:zoom',
      'product:filter',

      'product.field.ranking',
      'product.field.percentage',
      'product.field.percentage_label',
      'product.field.percentage_frames',
      'product.field.percentage_sds_ingredients',
      'product.field.price_active',

      'ingredient:list',
      'ingredient:compare',
      'ingredient:detail',
      'ingredient:filter',
      'ingredient:products',

      'ingredient.field.price_active',
      'ingredient.field.name',
      'ingredient.field.type',
      'ingredient.field.class_name',
      'ingredient.field.cas',
      'ingredient.field.ec_number',
      'ingredient.field.function',
      'ingredient.field.format',
      'ingredient.field.price_source',
      'ingredient.field.mol_weight',
      'ingredient.field.mol_format',
      'ingredient.field.sustainability_description',
      'ingredient.field.sustainability_rating',
      'ingredient.field.volume',
      'ingredient.field.volume_description',
    ],
  },
  admin: {
    static: [
      'product:list',
      'product:compare',
      'product:detail',
      'product:edit',
      'product:add',
      'product:delete',
      'product:zoom',
      'product:filter',

      'product.field.ranking',
      'product.field.percentage',
      'product.field.percentage_label',
      'product.field.percentage_frames',
      'product.field.percentage_sds_totals',
      'product.field.percentage_sds_ingredients',
      'product.field.price_active',
      'product.field.completeness',
      
      'ingredient:list',
      'ingredient:compare',
      'ingredient:detail',
      'ingredient:filter',
      'ingredient:edit',
      'ingredient:add',
      'ingredient:delete',

      'ingredient.field.price_active',
      'ingredient.field.name',
      'ingredient.field.type',
      'ingredient.field.class_name',
      'ingredient.field.cas',
      'ingredient.field.ec_number',
      'ingredient.field.function',
      'ingredient.field.format',
      'ingredient.field.price_source',
      'ingredient.field.mol_weight',
      'ingredient.field.mol_format',
      'ingredient.field.sustainability_description',
      'ingredient.field.sustainability_rating',
      'ingredient.field.volume',
      'ingredient.field.volume_description',

      'ingredient:zoom',
      'ingredient:products',

      'formula:list',
      'formula:compare',
      'formula:detail',
      'formula:edit',
      'formula:add',
      'formula:delete',
      'formula:zoom',

      'material:list',
      'material:detail',
      'material:add',
      'material:delete',
      'material:view',
      'material:edit',
      'material:filter',

      'import:page',

      'files:list',
      'files:filter',
    ],
  },
};

export default rules;
